import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import virtual_lesson from "../../assets/images/blog-image/virtual_lesson.jpg";
import vr from "../../assets/images/blog-image/vr.jpg";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

function MyCoolCodeBlock({ code, language, showLineNumbers }) {
    return (
        <CodeBlock
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={vs2015}
        />
    );
}

const articleData = {
    id: 19,
    title: "React Performance Profiling",
    desc: "identificare e risolvere i Colli di Bottiglia",
    img: "/blog-image/performance.jpg",
    page: "blog/react-performance",
    data: "22 Set 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "Sappiamo bene come React sia uno dei framework JavaScript più diffusi per la creazione di interfacce utente dinamiche e reattive.",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        Sappiamo bene come React sia uno dei
                                        framework JavaScript più diffusi per la
                                        creazione di interfacce utente dinamiche
                                        e reattive. Se da un lato la creazione
                                        di app complesse viene semplificata, è
                                        importante prestare attenzione alle
                                        relative prestazioni. In questo
                                        articolo, esploriamo l'importanza del
                                        profiling delle prestazioni in React e
                                        come identificare e risolvere i colli di
                                        bottiglia per garantire un'esperienza
                                        utente ottimale.
                                    </p>

                                    <h3>
                                        Perché profilare le prestazioni in React
                                        ?
                                    </h3>
                                    <p>
                                        Le prestazioni sono un aspetto critico
                                        dell'esperienza utente di qualsiasi
                                        applicazione web. Gli utenti si
                                        aspettano applicazioni veloci e reattive
                                        - qualsiasi rallentamento può portare a
                                        una frustrazione significativa. Ecco
                                        perché il profiling delle prestazioni in
                                        React è essenziale: si tratta del
                                        processo di misurazione e analisi delle
                                        performance di un'applicazione al fine
                                        di identificare aree di miglioramento.
                                        React offre diversi strumenti utili per
                                        ottenere una panoramica dettagliata
                                        delle prestazioni della tua app.
                                    </p>
                                    <h3>
                                        Strumenti di profilazione delle
                                        prestazioni in React
                                    </h3>
                                    <ol>
                                        <li>
                                            <h5>React DevTools</h5>
                                            <p>
                                                Le React DevTools sono
                                                un'estensione del browser che
                                                fornisce informazioni
                                                dettagliate sul rendering dei
                                                componenti React. È possibile
                                                visualizzare il tempo impiegato
                                                da ciascun componente per il
                                                rendering e l'aggiornamento,
                                                consentendo di identificare i
                                                componenti che potrebbero
                                                rallentare l'applicazione.
                                            </p>
                                        </li>
                                        <li>
                                            <h5>React Profiler</h5>
                                            <p>
                                                React Profiler è uno strumento
                                                incorporato in React che
                                                consente di registrare le
                                                interazioni degli utenti con
                                                l’applicazione e di analizzare
                                                il rendering dei componenti
                                                durante queste interazioni. In
                                                questo modo i componenti che
                                                richiedono molto tempo per il
                                                rendering sono identificati ed
                                                eventuali problemi risolti.
                                            </p>
                                        </li>
                                        <li>
                                            <h5>Performance Timeline</h5>
                                            <p>
                                                La Performance Timeline è uno
                                                strumento fornito dai browser
                                                che ti permette di registrare e
                                                analizzare il rendimento delle
                                                tue applicazioni React. Viene
                                                esaminato il tempo impiegato per
                                                il rendering, il caricamento
                                                delle risorse e altro ancora.
                                            </p>
                                        </li>
                                    </ol>
                                    <h3>
                                        Identificazione dei Colli di Bottiglia
                                    </h3>
                                    <p>
                                        Una volta avviati gli strumenti di
                                        profilazione delle prestazioni, è
                                        importante saper interpretare i dati
                                        raccolti. Ecco alcuni segnali comuni di
                                        possibili colli di bottiglia:
                                    </p>
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                    <ol>
                                        <li>
                                            <h5>
                                                Riduzione della Frequenza di
                                                Frame (FPS)
                                            </h5>
                                            <p>
                                                Un'applicazione React dovrebbe
                                                mirare a mantenere una frequenza
                                                di frame elevata - idealmente a
                                                60 FPS. Una frequenza più bassa
                                                indica rallentamenti
                                                nell'interfaccia utente.
                                                Identificare i momenti in cui la
                                                frequenza di frame scende ed
                                                individuare le cause è
                                                fondamentale.
                                            </p>
                                        </li>
                                        <li>
                                            <h5>Lunghe Catene di Rendering</h5>
                                            <p>
                                                Le catene di rendering troppo
                                                lunghe possono rallentare
                                                notevolmente il rendering
                                                dell'applicazione. Gli strumenti
                                                di profilazione permettono di
                                                individuare le catene lunghe e
                                                semplificano/ottimizzano i
                                                componenti responsabili.
                                            </p>
                                        </li>
                                        <li>
                                            <h5>Alte Richieste di Rete</h5>
                                            <p>
                                                Le richieste di rete possono
                                                rappresentare un collo di
                                                bottiglia nel momento in cui
                                                richiedono troppo tempo per
                                                completarsi. È quindi necessario
                                                monitorare le richieste di rete
                                                e cercare di ottimizzare il
                                                caricamento dei dati.
                                            </p>
                                        </li>
                                    </ol>
                                    <h3>Risoluzione dei Colli di Bottiglia</h3>
                                    <p>
                                        Una volta identificati i colli di
                                        bottiglia, è il momento di risolverli!
                                        Ecco alcune strategie comuni per
                                        migliorare le prestazioni di un'app
                                        React:
                                    </p>
                                    <ol>
                                        <li>
                                            <h5>
                                                Ottimizzazione del rendering
                                            </h5>
                                            <p>
                                                Semplificare i componenti React
                                                e usare “shouldComponentUpdate”
                                                o “PureComponent” per evitare
                                                rendering non necessari
                                            </p>
                                        </li>
                                        <li>
                                            <h5>
                                                Caricamento progressivo delle
                                                risorse
                                            </h5>
                                            <p>
                                                Utilizzare il caricamento
                                                progressivo delle risorse per
                                                caricare solo ciò di cui si ha
                                                necessità - evitare, quindi, il
                                                caricamento di tutti dati
                                                all’inizio.
                                            </p>
                                        </li>
                                        <li>
                                            <h5>
                                                Memorizzazione nella cache dei
                                                dati
                                            </h5>
                                            <p>
                                                Implementare una memorizzazione
                                                nella cache dei dati per evitare
                                                di chiedere dati già ottenuti in
                                                precedenza.
                                            </p>
                                        </li>
                                        <li>
                                            <h5>
                                                Parallelizzazione delle
                                                richieste di rete
                                            </h5>
                                            <p>
                                                Parallelizzare le richieste di
                                                rete quando possibile così da
                                                ridurre il tempo complessivo per
                                                il caricamento.
                                            </p>
                                        </li>
                                    </ol>
                                    <p>
                                        Le prestazioni sono una componente
                                        critica dell'esperienza dell'utente -
                                        rivolgiti ad un professionista. Richiedi
                                        una consulenza al nostro team di tecnici
                                        per ottimizzare o sviluppare la tua app
                                        React.
                                    </p>
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>

                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};

export default BlogDetails;
